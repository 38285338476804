<template>
    <div class="form-group text-center suspended-alert">
        <label class="text-danger small">
            <img alt class="warning-icon" src="../../../assets/img/auth/warning.svg"/>
            <slot name="message"></slot>
        </label>
    </div>
</template>

<script>
    export default {
        name: "SuspendedAlert"
    };
</script>

<style scoped>
    .suspended-alert {
        font-size: 16px;
    }
</style>
