<template>
    
</template>
<script>
import axios from "axios";
export default {
      name: "AutoLogin",
    data: () => ({
    loading: true,
  }),
  created() {
    console.log(this.$route.params)
    this.getUserInfoById(this.$route.params.id);
    
  },
  methods: {
    getUserInfoById(id) {
      const byUserId = this.$route.query.by_user_id ? true : false;
      const byHash   = this.$route.query.hash ? true : false;

      let url = `/get_user_info_by_cust_id/${id}?user_id=${byUserId}`;
      
      if (byHash) {
        id = this.encryptDecrypt(id, false);
        url = `/get_user_info_by_cust_id/${id}?hash=${byHash}`;
      }

      this.axios({
        method: "get",
        url,
      }).then((response) => {
        this.manageUserAuth(response.data);
      }).catch((error)=>{
          console.log(error)
          this.$router.push(`/login`);
      });
    },
    encryptDecrypt(baseString, en = true){
      if (en) {
          return window.btoa( baseString );
      } else {
          return window.atob( baseString );
      }
    },
    manageUserAuth(data) {
      this.$store.commit("setAccessToken", {
        token: data.access_token,
        expires: data.expires_in,
      });

      const planId = this.$route.query.plan ? this.$route.query.plan : false
      if (planId) {
        this.$router.push(`/checkout?plan=${planId}`);
        return false;
      }
      this.$nextTick(() => {
        this.$store.dispatch("getUserData").then((re) => {
          this.loading = false;
    
          if (re.data.user_status === 1) {
            if (
              re.data.billing_status === "Inactive" ||
              re.data.billing_status === "Expired"
              // !re.data.stripe_id ||
              // re.data.stripe_id === null ||
              // re.data.stripe_id === 'null' ||
              // re.data.stripe_id === 'NULL'
            ) {
              if (planId) {
                this.$router.push(`/checkout?plan=${planId}`);
              } else {
               this.$router.push(`/subscription`);
              }
              this.$forceUpdate();
            } else if (
              re.data.billing_status === "Cancelled" ||
              re.data.billing_status === "Failed"
            ) {
              window.BCookie.remove("BC-Auth-Email");
              this.errorsForm.accountCancelled = true;
              this.destroyAccessToken();
              this.goFirstStep();
            } else if (re.data.billing_status === "VerifyRequired") {
              window.BCookie.remove("BC-Auth-Email");
              this.errorsForm.accountNotVerified = true;
              this.errorsForm.email =
                "This account is not verified, please check your email.";
              this.userId = re.data.id;
              this.destroyAccessToken();
              this.goFirstStep();
            } else {
              const redirectUri = window.BCookie.check("redirect_path")
                ? window.BCookie.get("redirect_path")
                : "/";

              window.Ls.remove("BC-Auth-Remember");
              window.Ls.remove("sub-user-info");
              this.$router.push(redirectUri);
              this.$forceUpdate();
              this.takeToSubscriptionPage();
            }
          } else {
            window.BCookie.remove("BC-Auth-Email");
            this.errorsForm.accountCancelled = true;
            this.destroyAccessToken();
            this.goFirstStep();
          }
        });
      });
    },
    takeToSubscriptionPage() {
      const subPage = this.$route.query.sub_page;
      if (subPage) {
        this.$router.push({name : 'ChangeSubscriptionPlan', params: {'section': 'billing'} });
      }
    }
  },
 
}
</script>