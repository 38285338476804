<template>
  <div class="auth-area frm-login" id="login" v-cloak>
    <div class="login-area">
      <form @submit.prevent="nextStep" id="login-form" novalidate>
        <suspended-alert v-if="errorsForm.accountCancelled">
          <template slot="message">Your account has been disabled, opening payment page.</template>
        </suspended-alert>

        <div class="login-form">
          <auth-form-progress v-show="request_send"></auth-form-progress>

          <auth-form-header :login="true" :whitelabelBrandDetail="whitelabelBrandDetail">
            <template v-slot:header-label>{{ step === 1 ? "Sign in" : "Welcome!" }}</template>

            <template
              v-if="step === 1"
              v-slot:sub-header-label
            >Use your {{ $store.state.siteName }} Account</template>
            <template v-else v-slot:sub-header-label>
              <button
                @click="goFirstStep"
                class="btn btn-default auth-form-header-sub-label-email"
                type="button"
              >
                <img alt src="../../assets/img/auth/user-icon.svg" />
                <span class="login-selected-email">{{ credentials.email }}</span>
                <!--NEMESIS CHANGE <i class="fas fa-chevron-down"></i>-->
                <i class="fas">
                  <img src="../../assets/img/dropdown.svg" alt />
                </i>
              </button>
            </template>
          </auth-form-header>

          <div :class="{'password-body': step === 2}" class="auth-form-body">
            <auth-email-field
              :credentials="credentials"
              :errors-form="errorsForm"
              class="login-email-group"
              v-if="step === 1"
            ></auth-email-field>

            <div class="login-form-group" v-else>
              <label class="control-label" for="login-password">Password</label>

              <input
                :class="{'has-error': (errorsForm.passwordInvalid)}"
                :id="`login-password`"
                class="form-control login-password"
                placeholder="Enter password"
                type="text"
                v-if="showPasswordText"
                v-model="credentials.password"
              />

              <input
                :class="{'has-error': (errorsForm.passwordInvalid)}"
                class="form-control login-password"
                id="login-password"
                placeholder="Enter password"
                type="password"
                v-else
                v-model="credentials.password"
              />

              <span
                @click="showPasswordText = !showPasswordText"
                class="login-password-show login-form-item"
              >
                <img
                  alt
                  class="hide-password"
                  src="../../assets/img/auth/show.svg"
                  v-if="showPasswordText"
                />
                <img alt class="hide-password" src="../../assets/img/auth/hide.svg" v-else />
              </span>

              <label class="text-danger small" v-if="errorsForm.passwordInvalid">
                <img alt class="warning-icon" src="../../assets/img/auth/warning.svg" />
                {{ errorsForm.password }}
              </label>
            </div>

            <div class="row login-form-item verification-info">
              <div v-if="errorsForm.accountNotVerified" class="col-xs-12">
                <div
                  class="verified-message align-items-center"
                  style="padding-top: 2px"
                  :class="{'not-verified': userId}"
                >
                  <div
                    class="icon justify-content-center align-items-center"
                    style="display: inline-flex"
                  >
                    <img
                      v-if="!userId"
                      alt="checkmark-icon"
                      src="../../assets/img/checkmark.svg"
                      style="width: 14px;height: 14px"
                    />
                    <img
                      v-else
                      alt="exclamation-icon"
                      src="../../assets/img/exclamation.svg"
                      style="width: 14px;height: 14px"
                    />
                  </div>
                  <p v-if="userId">
                    {{errorsForm.email}}
                    <a href="javascript:void(0)" @click="resendEmail()">Resend</a>
                  </p>
                  <p v-else>The verification email has been resent successfully.</p>
                </div>
              </div>
            </div>

            <div class="row login-form-item login-check-box-row">
              <div class="col-xs-6">
                <el-checkbox
                  class="custom-el-checkbox"
                  @change="rememberMe"
                  v-model="credentials.remember_me"
                >Remember me</el-checkbox>
              </div>

              <div class="col-xs-6 text-right forgot-password-first">
                  <router-link :disabled="request_send" to="/forgot-password">Forgot password?</router-link>
              </div>
            </div>

            <div class="row login-body-footer">
              <div class="col-xs-12 text-center">
                <button
                  :disabled="request_send"
                  @click="nextStep"
                  class="btn btn-primary"
                  type="submit"
                >{{ step === 1 ? 'Next' : 'Sign in' }}</button>
              </div>

            </div>
          </div>
        </div>

        <support-link :whitelabelBrandDetail="whitelabelBrandDetail"></support-link>
      </form>
    </div>
    <auth-footer></auth-footer>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import AuthFooter from "./Items/AuthFooter";
import SupportLink from "./Items/SupportLink";
import AuthFormProgress from "./Items/AuthFormProgress";
import AuthFormHeader from "./Items/AuthFormHeader";
import SuspendedAlert from "./Items/SuspendedAlert";
import AuthEmailField from "./Items/AuthEmailField";
import GoogleLogin from "vue-google-login";

export default {
  name: "Login",
  props:['whitelabelBrandDetail'],
  components: {
    AuthEmailField,
    SuspendedAlert,
    AuthFormHeader,
    AuthFormProgress,
    AuthFooter,
    SupportLink,
    GoogleLogin,
  },
  data: () => ({
    credentials: {
      email: "",
      password: "",
      remember_me: false,
      refUserId: null,
      linkId: null,
    },
    step: 1,
    request_send: true,
    errorsForm: {
      email: "",
      requireEmail: "Email address is required",
      invalidEmail: "Invalid Email Address",
      emailNotFound: false,
      emailInvalid: false,
      password: "",
      passwordInvalid: false,
      accountCancelled: false,
      accountNotVerified: false,
    },
    userId: null,
    showPasswordText: false,
    params: {
      client_id:
        "638688976410-vivh8dj30ut84124t1rg1kgtkh70dn3q.apps.googleusercontent.com",
    },

  }),
  created() {
    this.step = parseInt(this.$route.params.step);
    this.credentials.refUserId = this.getCookie('ref')
    this.credentials.linkId = this.getCookie('lid')
// console.log(this.credentials.refUserId, 'Form');


if(this.$route.query.login_id){

  let post_data = {
              // url: process.env.VUE_APP_BACKEND_URL + "embed/login/" + this.$route.query.login_id,
              url: "embed/login/" + this.$route.query.login_id,

            };


            this.$store.dispatch("post", {...post_data})
                    .then(response => {
                    //  window.open('https://4kplayer.g2.gopanear.com/login/2?login_id=' + response.data.id, '_blank');
 let data = response.data;
 this.$store.commit("setAccessToken", {
            token: data.access_token,
            expires: data.expires_in,
          });
                     
                      this.$nextTick(() => {
            this.getUserData().then((re) => {

              

              window.Ls.remove("BC-Auth-Remember");
              window.Ls.remove("sub-user-info");
              this.$router.push('/');
              this.$forceUpdate();

              if (re.data.user_status === 1) {
              
              } else {
                window.BCookie.remove("BC-Auth-Email");
                this.errorsForm.accountCancelled = true;
                this.destroyAccessToken();
                this.goFirstStep();
              }
            });
          });

                    })
                    .catch(error => {

                    });

}

console.log(this.$route.query.login_id, 'this.$route.query.id');
 



  },
  mounted() {
    console.log('mount');
    this.step =
      this.$route.params && this.$route.params.step
        ? parseInt(this.$route.params.step)
        : 1;
    if (window.BCookie.get("BC-Auth-Email")) {
      this.credentials.email = window.BCookie.get("BC-Auth-Email");
      window.BCookie.remove("BCAccessToken");
      if (this.credentials.email) {
        this.axios
          .post(`/check-email`, {
            email: this.credentials.email,
          })
          .then((r) => {
            if (r.data.result === "success") {
              if (this.step === 1) {
                this.goSecondStep();
              }
            } else {
              this.goFirstStep();
            }
          });
      } else {
        if (this.step === 1) {
          this.goSecondStep();
        }
      }
    }

    if (
      window.Ls.get("BC-Auth-Remember") &&
      window.Ls.get("BC-Auth-Remember") === "true"
    ) {
      this.credentials.remember_me = true;
    }

    this.request_send = false;
    this.loadFacebookSDK(document, "script", "facebook-jssdk");
    this.initFacebook();
    // setTimeout(function () {
    //   document
    //     .querySelectorAll(".google-login button")[0]
    //     .setAttribute("type", "button");
    // }, 500);
  },
  methods: {
    getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },

    ...mapMutations({
      destroyAccessToken: "destroyAccessToken",
    }),

    ...mapActions({
      retrieveToken: "retrieveToken",
      getUserData: "getUserData",
    }),

    goSecondStep() {
      this.step = 2;
      this.$router.push(`/login/${this.step}`);
    },

    goFirstStep() {
      this.step = 1;
      this.$router.push(`/login/${this.step}`);
    },
    paymentRedirect(response) {
      const checkoutLink = response.data.checkout;
      console.log({response});
        if (checkoutLink) {
          setTimeout(() => {
            window.open(checkoutLink, '_blank');
          }, 3000);
        }
    },
    nextStep() {
      this.clearValidate();

      if (this.step === 1) {
        if (this.validateEmail()) {
          this.request_send = true;

          this.$http
            .post(`/check-email`, {
              email: this.credentials.email,
            })
            .then((r) => {
              this.request_send = false;
              if (r.data.result === "success") {
                window.BCookie.set(
                  "BC-Auth-Email",
                  this.credentials.email,
                  259200
                );
                this.errorsForm.accountNotVerified = false;
                this.goSecondStep();
              } else if (r.data.result === "cancelled") {
                this.errorsForm.accountCancelled = true;
                this.$router.push("/subscription");
                // this.$router.push(`/account_cancelled/${r.data.user.id}`);
              } else

                if (r.data.result == "verify-required") {
                this.errorsForm.accountNotVerified = true;
                this.errorsForm.email = r.data.message;
                this.userId = r.data.userId;
              } else {
                this.errorsForm.emailNotFound = true;
                this.errorsForm.email = r.data.message;
              }
            })
            .catch((e) => {
              this.request_send = false;
              this.errorsForm.emailInvalid = true;
            });
        } else {
          this.errorsForm.emailInvalid = true;
        }
      } else if (this.step === 2) {
        if (this.validatePassword()) {
          this.request_send = true;
          this.retrieveToken({ ...this.credentials })
            .then((response) => {
              console.log(response);
              this.request_send = false;
              if(typeof response.data.result!=='undefined' && !response.data.result){
                if(response.data.error=='config_pending'){
                   this.$router.push(`/complete_setup/${response.data.token}`);
                   return true;
                }
              }
              this.$nextTick(() => {
                
                this.getUserData()

                  .then((re) => {
                      console.log(re);
//                     if(re.data.whitelabel_licence && re.data.is_first_login){
                        
//  this.$router.push('whitelabel/beginning/info');
//   this.$forceUpdate();

//                     }

                    // const redirectUri = window.BCookie.check(
                    //         "redirect_path"
                    // )
                    //         ? window.BCookie.get("redirect_path")
                    //         : "/";

                     const redirectUri = "/projects";
                    window.Ls.remove("BC-Auth-Remember");
                    window.Ls.remove("sub-user-info");
                    this.$router.push(redirectUri);
                    this.$forceUpdate();

                  })
                  .catch((e) => {
                     console.log(e, '*-*-**-*-*-*-*');
                    this.destroyAccessToken();
                    this.goFirstStep();
                  });
              });
            })
            .catch((error) => {

              this.request_send = false;
              if (error.response.status === 403) {
                this.errorsForm.email = error.response.data;
                this.errorsForm.emailNotFound = true;
                this.goFirstStep();
              } else {
                this.errorsForm.password = error.response.data;
                this.errorsForm.passwordInvalid = true;
              }
            });
        } else {
          this.errorsForm.password =
            "Use 8 characters or more with a mix of letters, numbers & symbols.";
          this.errorsForm.passwordInvalid = true;
        }
      }
    },

    clearValidate() {
      this.errorsForm.passwordInvalid = false;
      this.errorsForm.emailInvalid = false;
      this.errorsForm.emailNotFound = false;
    },

    /**
     * Email validation check.
     * @returns {boolean}
     */
    validateEmail() {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.credentials.email).toLowerCase());
    },

    validatePassword() {
      // let re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
      // return re.test(String(this.credentials.password).toLowerCase());
      return true;
    },

    /**
     * Remember me
     */
    rememberMe() {
      if (this.credentials.remember_me) {
        window.Ls.set("BC-Auth-Remember", "true");
      } else {
        window.Ls.set("BC-Auth-Remember", "false");
      }
    },
    resendEmail() {
      // let url = process.env.VUE_APP_BACKEND_URL + "send-v-email/" + this.userId;
      let url = "send-v-email/" + this.userId;
      this.$http
        .get(url)
        .then((r) => {
          if (r.data.success) {
            this.userId = null;
          }
        })
        .catch((e) => {
          this.errorsForm.emailInvalid = true;
        });
    },
    /**/
    async logInWithFacebook() {
      window.FB.login(
        (response) => {
          if (response.authResponse) {

            let loginData = {
              id: response.authResponse.userID,
              type: "facebook",
            };

            window.FB.api(
              "/me",
              { fields: "id,name,email,link,gender,locale,picture" },
              (user) => {

                loginData["name"] = user.name;
                const picture = user.picture;
                const pictureDate =
                  typeof picture.data !== "undefined" ? picture.data : "";

                loginData["photo_url"] =
                  typeof pictureDate.url !== "undefined" ? pictureDate.url : "";
                loginData["email"] =
                  typeof user.email !== "undefined" ? user.email : "";
                this.socialLogin(loginData);
              }
            );
          } else {
            alert("User cancelled login or did not fully authorize.");
          }
        },
        { scope: "email,user_likes", return_scopes: true }
      );
      return false;
    },
    async initFacebook() {
      window.fbAsyncInit = function () {
        window.FB.init({
          // appId: process.env.facebookId, //You will need to change this
          appId: "578439953088499", //You will need to change this
          cookie: true, // This is important, it's not enabled by default
          version: "v2.10",
          xfbml: true,
        });
      };
    },
    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },
    socialLogin(data) {
      this.axios({
        method: "post",
        url: "/social_login",
        params: data,
      })
        .then((response) => {
          let data = response.data;

          this.$store.commit("setAccessToken", {
            token: data.access_token,
            expires: data.expires_in,
          });
          this.$nextTick(() => {
            this.getUserData().then((re) => {

              const redirectUri = window.BCookie.check("redirect_path")
                      ? window.BCookie.get("redirect_path")
                      : "/projects";

              window.Ls.remove("BC-Auth-Remember");
              window.Ls.remove("sub-user-info");
              this.$router.push(redirectUri);
              this.$forceUpdate();

              if (re.data.user_status === 1) {
              //   if (
              //     re.data.billing_status === "Inactive" ||
              //     re.data.billing_status === "Expired"
              //   ) {
              //     this.$router.push("/subscription");
              //     this.$forceUpdate();
              //   } else if (
              //     re.data.billing_status === "Cancelled" ||
              //     re.data.billing_status === "Failed"
              //   ) {
              //     window.BCookie.remove("BC-Auth-Email");
              //     this.errorsForm.accountCancelled = true;
              //     this.destroyAccessToken();
              //     this.goFirstStep();
              //   } else if (re.data.billing_status === "VerifyRequired") {
              //     window.BCookie.remove("BC-Auth-Email");
              //     this.errorsForm.accountNotVerified = true;
              //     this.errorsForm.email =
              //       "This account is not verified, please check your email.";
              //     this.userId = re.data.id;
              //     this.destroyAccessToken();
              //     this.goFirstStep();
              //   } else {
              //     const redirectUri = window.BCookie.check("redirect_path")
              //       ? window.BCookie.get("redirect_path")
              //       : "/";
              //
              //     window.Ls.remove("BC-Auth-Remember");
              //     window.Ls.remove("sub-user-info");
              //     this.$router.push(redirectUri);
              //     this.$forceUpdate();
              //   }
              } else {
                window.BCookie.remove("BC-Auth-Email");
                this.errorsForm.accountCancelled = true;
                this.destroyAccessToken();
                this.goFirstStep();
              }
            });
          });
        })
        .catch(function (response) {
          console.log(response.message);
        });
    },
    onSuccess(googleUser) {
      let user = googleUser.getBasicProfile();
      let loginData = {};

      loginData["name"] = user.getName();
      loginData["email"] = user.getEmail();
      loginData["id"] = user.getId();
      loginData["photo_url"] = user.getImageUrl();
      loginData["type"] = "gmail";
      this.socialLogin(loginData);
    },
    onFailure(googleUser) {
      console.log(googleUser);

      // This only gets the user information: id, name, imageUrl and email
      //console.log(googleUser.getBasicProfile());
    },
  },
};
</script>
<!-- NEMESIS START -->
<style lang="less" scoped>
@import "~element-ui/lib/theme-chalk/checkbox.css";


.auth-area{
  width: 100% ;
}
</style>
<!-- NEMESIS END -->
<style lang="less" src="../../assets/less/custom/pages.less"></style>
<style lang="less" src="../../assets/less/custom/auth.less"></style>
